import React, {useState, useEffect, useContext} from "react"
import { graphql } from "gatsby"
import loadable from '@loadable/component'

import * as R from "ramda"

import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import SearchContext from "../contexts/SearchContext"

import Layout from "../components/layout";
import SEO from "../components/SEO";
import Hero from "../components/page/Hero";
import SearchBox from "../components/page/search/SearchBox";
const Profile = loadable(() => import("../components/page/main/Profile"))
const Highlight = loadable(() => import("../components/page/main/Highlight"))
const References = loadable(() => import("../components/page/main/References"))
const FeaturedContent = loadable(() =>
  import("../components/page/main/FeaturedContent")
)
const FeaturedPages = loadable(() => import("../components/page/FeaturedPages"))
const PremisesHighlight = loadable(() =>
  import("../components/page/main/PremisesHighlight")
)

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: "2.5rem",
  },
  lastSection: {
    marginTop: "4.5rem",
    marginBottom: "0",
  },
}))

const IndexPage = ({ data }) => {

  // For the main index, always reset the serach filters */
  const searchContext = useContext(SearchContext)
  useEffect(() => {
    if (Object.keys(searchContext.filters).length > 0) {
      searchContext.resetSearch()
    }
  }, [])

  const featuredReferences = R.path(
    ["contentfulEtusivu", "featuredReferences"],
    data
  )

  // Pad the featured articles with latest posts (if less than 5)
  const featuredArticles = R.path(
    ["contentfulEtusivu", "featuredArticles"],
    data
  )
    ? R.path(["contentfulEtusivu", "featuredArticles"], data)
    : []
  const featuredArticleIDs =
    featuredArticles.length > 0 ? featuredArticles.map(a => a.id) : []
  const articles = featuredArticles.concat(
    R.path(["extraArticles", "nodes"], data)
      .filter(article => !featuredArticleIDs.includes(article.id))
      .sort(() => 0.5 - Math.random())
      .slice(0, 5 - featuredArticles.length)
  )

  // Pad the featured service offerings with latest posts (if less than 3)
  const featuredServiceOfferings = R.path(
    ["contentfulEtusivu", "featuredServiceOfferings"],
    data
  )
    ? R.path(["contentfulEtusivu", "featuredServiceOfferings"], data)
    : []
  const featuredServiceOfferingIDs =
    featuredServiceOfferings.length > 0
      ? featuredServiceOfferings.map(so => so.id)
      : []
  const serviceOfferings = featuredServiceOfferings.concat(
    R.path(["extraServiceOfferings", "nodes"], data)
      .filter(so => !featuredServiceOfferingIDs.includes(so.id))
      .sort(() => 0.5 - Math.random())
      .slice(0, 3 - featuredServiceOfferings.length)
  )

  const classes = useStyles()

  const [loaded, setLoaded] = useState(false)
  const compLoadedHere = () => {
    setLoaded(true);
  }

  return (
    <Layout>
      <SEO title="Toimitilahaku" />

      <Hidden smDown><Hero
        headline={R.path(["contentfulEtusivu", "pageHeadingTitle"], data)}
        backgroundImageFluid={R.path(
          ["contentfulEtusivu", "pageHeadingImage", "gatsbyImageData"],
          data
        )}
      >
        <SearchBox compLoaded={() => compLoadedHere()}></SearchBox>
      </Hero></Hidden>
      <Hidden mdUp><Hero
        headline={R.path(["contentfulEtusivu", "pageHeadingTitle"], data)}
      >
        <SearchBox></SearchBox>
      </Hero></Hidden>

      

      {/* Featured Premises */}
      {loaded && <PremisesHighlight
        title={R.path(["contentfulEtusivu", "searchResultHeading"], data)}
        featuredPremises={R.path(["featuredPremises", "nodes"], data)}
        additionalPremises={R.path(["latestPremises", "nodes"], data)}
      />}

      {/* Newsec At a Glance */}
      {loaded && <Box className={classes.section}>
        <Profile
          heading={R.path(["contentfulEtusivu", "newsecInfoHeading"], data)}
          text={R.path(
            ["contentfulEtusivu", "newsecInfoText", "newsecInfoText"],
            data
          )}
          infoBalls={R.path(["contentfulEtusivu", "newsecInfoBalls"], data)}
        />
      </Box>}

      {/* Featured Articles */}
      {(articles && loaded) && (
        <Box className={classes.section}>
          <FeaturedContent items={R.slice(0, 2, articles)} />
        </Box>
      )}

      {loaded && <Box className={classes.section}>
        {/* Newsec Intro & Link */}

        <Highlight
          heading={R.path(["contentfulEtusivu", "newsecAboutHeading"], data)}
          lead={R.path(
            ["contentfulEtusivu", "newsecAboutText", "newsecAboutText"],
            data
          )}
          backgroundImageFluid={R.path(
            ["contentfulEtusivu", "newsecAboutBackgroundImage", "gatsbyImageData"],
            data
          )}
          link={R.path(["contentfulEtusivu", "newsecAboutLink"], data)}
        />

        {/* Featured References (full width) */}
        {featuredReferences && <References references={featuredReferences} />}
      </Box>}

      {/* Featured Services */}
      {(serviceOfferings&& loaded) && (
        <Box className={classes.section}>
          <FeaturedContent items={serviceOfferings} />
        </Box>
      )}

      {/* Tarinat Latest */}
      {(articles && loaded) && (
        <Box className={classes.sectionBottom}>
          <FeaturedPages
            title="TARINAT"
            linkTo="/tarinat"
            data={R.slice(2, 5, articles)}
          ></FeaturedPages>
        </Box>
      )}
    </Layout>
  )
}

export default IndexPage

// This is temporary taking the data from the old format & it will just return all the premises data. It also just takes the first 3 results, in the final data we will makes ure we get the most relevent results
// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query indexPageQuery {
    contentfulEtusivu(activeVersion: { eq: "Kyllä" }) {
      pageHeadingTitle
      pageHeadingImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 30,
        )
      }
      searchResultHeading
      newsecInfoHeading
      newsecInfoText {
        newsecInfoText
      }
      newsecInfoBalls {
        metric
        description
        icon {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 150, 
            quality: 80,
          )
        }
      }
      newsecAboutHeading
      newsecAboutText {
        newsecAboutText
      }
      newsecAboutBackgroundImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 450, 
          quality: 90,
        )
      }
      newsecAboutLink
      featuredReferences {
        name
        role
        workplace
        testimonial {
          testimonial
        }
      }
      featuredServiceOfferings {
        id
        slug
        title
        heading
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 300, 
            quality: 50,
          )
        }
        leadText {
          leadText
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      featuredArticles {
        id
        slug
        title
        heading
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 300, 
            quality: 50,
          )
        }
        leadText {
          leadText
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    extraArticles: allContentfulArticle(
      filter: { publishedDate: { ne: null }, slug: { regex: "/^[^_]/" } }
      sort: { fields: publishedDate, order: DESC }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        heading
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 300, 
            quality: 50,
          )
        }
        leadText {
          leadText
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    extraServiceOfferings: allContentfulServiceOffering(
      filter: { slug: { regex: "/^[^_]/" } }
      sort: { fields: updatedAt, order: DESC }
      limit: 3
    ) {
      nodes {
        id
        slug
        title
        heading
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 300, 
            quality: 50,
          )
        }
        leadText {
          leadText
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    featuredPremises: allNewsecPremises(
      sort: { fields: id, order: DESC }
      filter: { promoted: { eq: "true" } }
    ) {
      nodes {
        slug
        streetAddress
        brandName
        areaToUse
        areas
        city
        postcode
        types
        size {
          advertiseSqMeters
        }
        promoted
        media {
          main {
            alt
            ext
            id
            name
            picClass
            picType
            prio
          }
        }
      }
    }
    latestPremises: allNewsecPremises(
      sort: { fields: id, order: DESC }
      filter: { promoted: { ne: "true" } }
      limit: 6
    ) {
      nodes {
        slug
        streetAddress
        brandName
        areaToUse
        areas
        city
        postcode
        types
        size {
          advertiseSqMeters
        }
        promoted
        media {
          main {
            alt
            ext
            id
            name
            picClass
            picType
            prio
          }
        }
      }
    }
  }
`
